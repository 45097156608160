<template>
  <div class="order">
    <r-header :title="'确认订单'" :isLeftArrow="true" :isR="true" @back="backPage"></r-header>
    <div class="container">
      <van-cell-group>
        <van-cell v-for="item in list" :key="item.id">
          <!-- 使用 title 插槽来自定义标题 -->
          <template #title>
            <div class="flex">
              <!-- <div class="order-img">
                <span class="store-text">{{item.rw}}{{item.cl}}</span>
              </div> -->
              <section>
                <p class="store-name">{{ item.shopname }} <span class="text money">￥ <span class="text-m">{{ fmoney(item.shopprice, 2) }}</span></span></p>
                <!-- <p class="order-time margin-t-1" style="margin-top:8px;">租赁周期：{{ item.starttime }}</p> -->
                <p class="order-time margin-t-1" style="margin-top:8px;">开始时间：{{ item.starttime }}</p>
                <p class="order-time">结束时间：{{ item.endtime }}</p>
                <p class="tip-text" v-if="item.orderstatus === '2'" >{{ item.msg }}</p>
              </section>
            </div>
          </template>
          <!-- <template #extra>
            <div class="flex justify-end align-center">
              <span class="mr-10">周</span><van-switch v-model="item.checked" class="mr-10" /><span>月</span>
            </div>
          </template> -->
        </van-cell>
        <van-cell title="一旦选择非本公司原因不得退费" icon="shop-o">
          <!-- 使用 right-icon 插槽来自定义右侧图标 -->
          <template #right-icon>
            <!-- <p>共{{ idArr.length }}个摊位 -->
              <!-- 单价¥ {{ list.length > 0 && fmoney(list[0].shopprice, 2) }} -->
            <!-- </p> -->
          </template>
        </van-cell>
        <!-- <van-cell :title="`市场在${starttime}至${endtime}期间的周六日开放摊位`" icon="info-o"></van-cell> -->
      </van-cell-group>
      <van-cell-group style="margin-top:20px">
        <van-cell>
          <!-- 使用 title 插槽来自定义标题 -->
          <template #title>
            <div class="flex align-center">
              <div class="phone-img"></div>
              <section>
                <p class="user-phone">{{ phone }}</p>
                <p class="order-text">手机号仅用于生成订单</p>
              </section>
            </div>
          </template>
        </van-cell>
      </van-cell-group>
      <van-cell-group style="margin-top:20px" v-if="yaoqiu">
        <van-cell title="购买须知"></van-cell>
        <van-cell>
          <template #title>
            <div class="">
              <p v-html="yaoqiu"></p>
            </div>
          </template>
        </van-cell>
      </van-cell-group>
      <van-submit-bar :price="Number(totalPrice)" v-click-with-auth-login="onSubmit" v-if="nextpay">
        <!-- <template #default>
          <span @click="tipsControl"><van-icon :name="tips ? 'arrow-up' : 'arrow-down'" size="10" />明细</span>
        </template> -->
        <template #button>
          <van-button v-if="cancel" round type="default">取消订单</van-button>
          <van-button :disabled="totalPrice === 0" round color="linear-gradient(to right, #ff6034, #ee0a24)" >
            立即购买
          </van-button>
        </template>
        <!-- <template #tip v-if="tips">
          月票摊主，每月的月底提前一周联系客服进行下个月的续租。
        </template> -->
      </van-submit-bar>
    </div>
    <van-overlay :show="show" class="overlay" />
  </div>
</template>

<script>
import RHeader from '@/components/RHeader.vue'
export default {
  components: {
    RHeader
  },
  data () {
    return {
      tips: true,
      cancel: false,
      shop: {},
      show: false,
      // idArr: [],
      list: [],
      totalPrice: 0,
      id: '',
      batchid: '',
      starttime: '',
      endtime: '',
      nextpay: false
    }
  },
  computed: {
    phone () {
      return this.$store.getters.getUsername
    },
    yaoqiu () {
      return this.list.length > 0 ? this.list[0].yaoqiu : ''
    }
  },
  created () {
    // this.idArr = this.$route.query.id.split(',')
    if (!this.$route.query.id) {
      this.backPage()
    } else {
      this.initData()
      console.log(this.list)
    }
  },
  mounted () {
  },
  methods: {
    onSubmit () {
      this.show = true
      if (this.show) {
        const redirect = 'http://www.whwwsc.com/movie/h5pay/'
        const orderId = `1*${this.batchid}`
        window.location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=wx63c208bb195251ca&redirect_uri=${encodeURIComponent(redirect)}&response_type=code&scope=snsapi_userinfo&state=${orderId}#wechat_redirect`
      }
    },
    tipsControl () {
      this.tips = !this.tips
    },
    initData () {
      const arr = this.$route.query.id
      console.log(arr)
      this.$axios.post('/gw/index2', { batchid: arr }).then(res => {
        console.log(res.data.data)
        if (res.data.code === 200) {
          const { data, totalamt, nextpay } = res.data
          console.log(totalamt)
          // data.map(item => { item.checked = false })
          this.list = [...data]
          this.totalPrice = totalamt
          this.id = data[0]?.id
          this.batchid = data[0]?.batchid
          this.starttime = data[0]?.starttime
          this.endtime = data[0]?.endtime
          this.nextpay = nextpay
        }
      })
    },
    backPage () {
      this.$router.push({
        name: 'choose'
      })
    }
  }
}
</script>

<style lang="less">
.store-name{
  font-size: 20px;
  font-weight: bold;
}
.tip-text {
  font-size: 12px;
  color: #ee0a24;
}
.order-img {
  width: 80px;
  height: 80px;
  border-radius: 5px;
  background: green;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 15px;
}
.store-text{
  font-size: 32px;
  padding: 0;
  margin: 0;
  color: #fff;
}
.order-time{
  font-size: 16px;
  color: rgb(51, 51, 51);
}
.margin-t-2{
  margin-top: 20px;
}
.user-phone{
  font-size: 18px;
}
.order-text{
  color: #666;
}
.phone-img{
  width:50px;
  height:50px;
  background: url(../assets/images/phone.png) no-repeat;
  background-size: cover;
  margin-right: 15px;
}
.overlay{
  z-index: 4000!important;
}
</style>
